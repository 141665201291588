// Footer.js
import React from 'react';
import { Box, Container, Stack, Text, IconButton, useColorModeValue } from '@chakra-ui/react';
import { FaTwitter, FaFacebook, FaInstagram } from 'react-icons/fa';

const Footer = () => {
    return (
        <Box bg={useColorModeValue('gray.100', 'gray.700')} p={4}>
            <Container maxW="container.xl">
                <Stack spacing={4}>
                    <Text>&copy; {new Date().getFullYear()} Your Company. All rights reserved.</Text>
                    {/* Social Media Icons */}
                    <Stack direction={'row'} spacing={6}>
                        <IconButton 
                            aria-label="Twitter" 
                            icon={<FaTwitter />} 
                            size="md" 
                            color={'white'} 
                            bg={'twitter.400'} 
                            _hover={{ bg: 'twitter.500' }} 
                        />
                        <IconButton 
                            aria-label="Facebook" 
                            icon={<FaFacebook />} 
                            size="md" 
                            color={'white'} 
                            bg={'facebook.400'} 
                            _hover={{ bg: 'facebook.500' }} 
                        />
                        <IconButton 
                            aria-label="Instagram" 
                            icon={<FaInstagram />} 
                            size="md" 
                            color={'white'} 
                            bg={'pink.400'} 
                            _hover={{ bg: 'pink.500' }} 
                        />
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
};

export default Footer;
